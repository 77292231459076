<template>
    <v-container fluid>
      <customer-report-title :title="options.title" class="mb-2" />
      <customer-report-sub-category :tabs="options.tabs" @select-tab="selectTab" />
      <customer-report-filter
        :organizations="options.organizations"
        @load-report="fetchReport($event)"
        @export-table="exportTableToXLSX($event)"
      />
      <customer-report-kpi-bar />
      <customer-report-table :headers="options.headers" :items="options.data" />
      <customer-report-alert />
    </v-container>
  </template>
  
  <script>
  import { Vue } from "vue-property-decorator";
  import { get, sync } from "vuex-pathify";
  import { format, startOfYear } from "date-fns";
  import { mapActions, mapState } from "vuex";
  import { json2xlsx } from "@/util/helpers";
  
  export default Vue.extend({
    name: "ReportTransport",
    components: {
      CustomerReportTitle: () => import("@/components/report/customerreport/CustomerReportTitle.vue"),
      CustomerReportChart: () => import("@/components/report/customerreport/CustomerReportChart.vue"),
      CustomerReportSubCategory: () =>
        import("@/components/report/customerreport/CustomerReportSubCategory.vue"),
      CustomerReportFilter: () => import("@/components/report/customerreport/CustomerReportFilter.vue"),
      CustomerReportKpiBar: () => import("@/components/report/customerreport/CustomerReportKPIBar.vue"),
      CustomerReportTable: () => import("@/components/report/customerreport/CustomerReportTable.vue"),
      CustomerReportAlert: () => import("@/components/report/customerreport/CustomerReportAlert.vue")
    },
    computed: {
      options: get("report/optionsTransport"),
      ...sync("report", ["currentTab"]),
      errorMessage: get("report/errorMessages@export"),
      ...mapState({ series: state => state.report.series }),
      ...mapState({ chartOptions: state => state.report.chartOptions })
    },
    data() {
      return {
        json2xlsx
      };
    },
    methods: {
      ...mapActions({
        resetReport: "report/resetTransportReport",
        // fetchOrganizations: "report/fetchTransportOrganizations",
        fetchReport: "report/fetchTransportData"
      }),
      initialize() {
        this.currentTab = this.options.tabs[0];
        this.selectTab(this.currentTab);
      },
      selectTab(tab) {
        const link = tab.link.toLowerCase();
        // this.fetchOrganizations(link);
        this.resetReport(tab);
        this.showDefaultChart(link);
      },
      showDefaultChart(link) {
        const dateFrom = format(startOfYear(Date.now()), "yyyy-MM-dd");
        const dateTo = format(Date.now(), "yyyy-MM-dd");
        const organizationId = localStorage.getItem("organizationId");
        const options = {
          path: link,
          params: {
            organizationId: organizationId,
            date_from: dateFrom,
            date_to: dateTo
          }
        };
        this.fetchReport(options);
      },
      exportTableToXLSX(currentTab) {
        this.options.data.length > 0
          ? json2xlsx({
              data: this.options.data,
              header: [this.options.headers.map(header => header.text)],
              sheetName: "Отчет",
              fileName:
                "ИСУНГ - " + this.options.title + " - " + currentTab.name + ".xlsx"
            })
          : console.log(this.errorMessage);
      }
    },
    async mounted() {
      this.initialize();
    }
  });
  </script>
  